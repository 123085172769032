import React from "react"
import cn from "classnames"

interface CirclePropsTypes {
  isSolid?: boolean
  color?: string
  className?: string
}

const Circle: React.FC<CirclePropsTypes> = ({ isSolid, color, className }) => {
  const solidCircle = (
    <div
      className={cn(`circle`, className)}
      style={{
        borderRadius: "50%",
        backgroundColor: color,
      }}
    />
  )
  const wholeCircle = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      className={cn(`circle`, className)}
      viewBox="0 0 76 77"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M76 38.5c0 20.987-17.013 38-38 38S0 59.487 0 38.5 17.013.5 38 .5s38 17.013 38 38zm-30.216.017a8.176 8.176 0 01-8.176 8.176 8.176 8.176 0 118.176-8.176z"
        clipRule="evenodd"
      ></path>
    </svg>
  )

  return isSolid ? solidCircle : wholeCircle
}
export default Circle

Circle.defaultProps = {
  isSolid: false,
  color: ``,
  className: ``,
}
